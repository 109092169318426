import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/index.js";
import InstagramEmbed from 'react-instagram-embed';
import Header from 'components/Header';
import Hero from 'components/Hero';
import Callout from 'components/Callout';
import Accordion from 'components/Accordion';
import BadgeList from 'components/BadgeList';
import Conversion from 'components/Conversion';
import Footer from 'components/Footer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Header mdxType="Header" />
    <Hero mdxType="Hero" />
    <Callout name="Callout1" mdxType="Callout" />
    <Callout name="Callout2" mdxType="Callout">
  <BadgeList layout="four" badges={[{
        id: "backed-by-science",
        label: "Backed by Science",
        icon: '/images/icon-science.png'
      }, {
        id: "physician-created",
        label: "Physician Created",
        icon: '/images/icon-physicians.png'
      }, {
        id: "high-protein",
        label: "High Protein",
        icon: '/images/icon-high-protein.png'
      }, {
        id: "antioxidents",
        label: "Antioxident & Nutrient Rich",
        icon: '/images/icon-antioxidants.png'
      }, {
        id: "no-added-sugar",
        label: "No Added Sugar",
        icon: '/images/icon-no-sugar.png'
      }, {
        id: "soy-free",
        label: "Soy Free",
        icon: '/images/icon-no-soy.png'
      }, {
        id: "gluten-free",
        label: "Gluten Free",
        icon: '/images/icon-gluten-free.png'
      }, {
        id: "non-gmo",
        label: "Non-GMO",
        icon: '/images/icon-non-gmo.png'
      }]} mdxType="BadgeList" />
    </Callout>
    <Callout name="Callout3" mdxType="Callout" />
    <Callout name="Callout4" id="smoothies" mdxType="Callout">
  <section>
  <Accordion id="smoothie-flavors" heading="Smoothie Flavors" items={[{
          active: true,
          id: `banana-strawberry`,
          heading: `Banana Strawberry`,
          content: `Succulent strawberries combine with the sweet, firmness of bananas in a flavorful, energy-boosting blend.
Ingredients: Strawberry, Banana, Plant Protein, Apple, Pineapple
No added sugar. No artificial anything.
`
        }, {
          id: `mango-pineapple`,
          heading: `Mango Pineapple`,
          content: `Exotic blend of mango and pineapple take your taste buds on a tour of the tropics. Heart healthy, antioxidant packed, and delicious.
Ingredients: Mango, Pineapple, Plant Protein, Apple, Banana, Passion Fruit
No added sugar. No artificial anything.`
        }, {
          id: `cherry-acai`,
          heading: `Cherry Acai`,
          content: `The acai has the greatest antioxidant power of the super fruits and is packed with vitamins and essential fats. Its benefits are supercharged once combined with cherry and other fruits.
Ingredients: Cherry, Acai, Plant Protein, Banana, Apple, Lemon
No added sugar. No artificial anything.`
        }, {
          id: `chocolate-banana`,
          heading: `Chocolate Banana`,
          content: `Guilt-free, rich & velvety chocolate with a hint of creamy banana.
Ingredients: Banana, Plant Protein, Cocoa Powder
No added sugar. No artificial anything.`
        }]} mdxType="Accordion" />
  </section>
  <section className={'flavor-art'}>
    <img src="/images/bottles.gif" alt="Smoothie Flavors" />
  </section>
    </Callout>
    <Callout name="Callout5" id="our-story" mdxType="Callout" />
    <Callout name="Callout6" mdxType="Callout">
  <InstagramEmbed url='https://www.instagram.com/p/CBRvCVYAWDX/' maxWidth={320} hideCaption={true} containerTagName='div' injectScript protocol='' className={"ig-embed"} mdxType="InstagramEmbed" />
  <InstagramEmbed url='https://www.instagram.com/p/CBlUUT_J3yX/' maxWidth={320} hideCaption={true} containerTagName='div' injectScript protocol='' className={"ig-embed"} mdxType="InstagramEmbed" />
  <InstagramEmbed url='https://www.instagram.com/p/CBlNQjHpWGM/' maxWidth={320} hideCaption={true} containerTagName='div' injectScript protocol='' className={"ig-embed hidden--mobile"} mdxType="InstagramEmbed" />
  <InstagramEmbed url='https://www.instagram.com/p/CBlUY7wp37m/' maxWidth={320} hideCaption={true} containerTagName='div' injectScript protocol='' className={"ig-embed hidden--mobile"} mdxType="InstagramEmbed" />
    </Callout>
    <Conversion id="try-it" mdxType="Conversion" />
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      